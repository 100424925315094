import { Typography } from "@material-tailwind/react";
import React from "react";
import "./style.css";
import bannerImg from "../../assets/images/banner_img.jpg";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
export default function Banner() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [cursor, setCursor] = React.useState("pointer");
  const handleScrollToSection = () => {
    const section = document.getElementById("services");
    section.scrollIntoView({ behavior: "smooth" });
    setCursor((prevState) => {
      return prevState === "pointer" ? "default" : "pointer";
    });
  };
  return (
    <section id="banner">
      {isMobile ? (
        <div
          class="bg-center bg-no-repeat bg-[url('https://images.unsplash.com/photo-1459767129954-1b1c1f9b9ace?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80')] bg-gray-600 bg-blend-multiply"
          data-aos="fade-down"
        >
          <div
            className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            <h1 className="mb-4 text-5xl font-notoSansHK font-[700] tracking-tight leading-none md:text-5xl lg:text-6xl text-[white]">
              Redefining the Construction Experience
            </h1>
            <p className="mb-8 text-lg font-roboto text-gray-300 lg:text-xl sm:px-16 lg:px-48">
              Here at company , we provide expert guidance and support for your
              construction projects. We are your trusted partner in redefining
              the construction experience.
            </p>
            <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
              <Link
                to="/"
                onClick={() => {
                  const section = document.querySelector("#contact-us");
                  section.scrollIntoView({ behavior: "smooth" });
                }}
                className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-[#FF6331] hover:bg-[#FF6200]"
              >
                Get started
                <svg
                  aria-hidden="true"
                  className="ml-2 -mr-1 w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </Link>
              <Link
                to="/"
                onClick={handleScrollToSection}
                className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400"
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-banner" data-aos="fade-down">
          <div className="relative md:mb-[15%]">
            <div
              className="py-4 md:px-20 px-4 md:pt-20 pt-4"
              data-aos="fade-right"
              data-aos-delay="300"
              data-aos-duration="500"
            >
              <Typography
                variant="h1"
                className="text-[#FFF] font-notoSansHK font-[900] text-[3rem] lg:text-[4rem] lg:leading-[5.2rem] leading-[3.2rem] md:mb-[5%] lg:mb-0 sm:w-[40%] w-80% text-left ml-[5%] custom-blend"
              >
                Redefining the Construction Experience
              </Typography>
              {/* Circle Button */}
              <div className="relative w-20 h-20 ml-[5%] mt-[2%]">
                {/* Concentric */}
                <div className="absolute -inset-5 flex items-center justify-center h-[120px] w-[120px]"></div>
                <div className="absolute -inset-5 flex items-center justify-center transition duration-300 ease-in-out bg-[#fff] bg-opacity-100 rounded-full h-[120px] w-[120px]"></div>
                {/* Inner Circle */}
                <div className="absolute inset-0 flex items-center justify-center h-[80px] w-[80px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-12 w-12 text-[#ffffff]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </div>
                <div
                  onClick={handleScrollToSection}
                  style={{ cursor: cursor }}
                  className="absolute inset-0 flex items-center justify-center transition duration-300 ease-in-out bg-[#111e25] bg-opacity-100 rounded-full hover:bg-opacity-80 h-[80px] w-[80px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 text-[#ffffff]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              className="md:absolute md:top-[20%] md:right-0 w-[100%] md:w-[45%] object-cover"
              data-aos="fade-left"
              data-aos-delay="300"
              data-aos-duration="500"
            >
              <img
                src={bannerImg}
                alt="banner"
                className="clip-img object-cover"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
