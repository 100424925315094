import React from "react";
import { Button } from "@material-tailwind/react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "yup-phone";
const Formschema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email().required("Email is required"),
  message: yup.string().required("Message is required"),
  phone: yup.string().phone("IN", true).required("Phone Number is required"),
});

export default function ContactForm() {
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(Formschema),
  });

  const handleFormSubmit = handleSubmit(async (data) => {
    console.log("Submitted", data);
    if (Formschema.isValid()) {
      try {
        const response = await fetch(
          "https://sscbackend-app-styho.ondigitalocean.app/api/form/postForm",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              phone: data.phone,
              message: data.message,
            }),
          }
        );
        if (response.status === 200 || response.status === 201) {
          alert("Message sent successfully");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsFormSubmitted(true);
      }
    }
  });
  return (
    <section>
      <div class="flex items-center justify-start mt-[10%]">
        <div class="w-full max-w-lg">
          {!isFormSubmitted ? (
            <form onSubmit={handleFormSubmit}>
              <div class="grid gap-8 sm:grid-cols-2">
                <div class="relative z-0">
                  <input
                    type="text"
                    name="firstName"
                    class="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                    placeholder=" "
                    {...register("firstName")}
                  />
                  <label class="font-roboto absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">
                    First Name
                  </label>
                  <div className="font-roboto text-[red] text-[0.8rem]">
                    {errors.firstName?.message}
                  </div>
                </div>
                <div class="relative z-0">
                  <input
                    type="text"
                    name="lastName"
                    class="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                    placeholder=" "
                    {...register("lastName")}
                  />
                  <label class="font-roboto absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">
                    Last Name
                  </label>
                  <div className="font-roboto text-[red] text-[0.8rem]">
                    {errors.lastName?.message}
                  </div>
                </div>
                <div class="relative z-0">
                  <input
                    type="text"
                    name="email"
                    class="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                    placeholder=" "
                    {...register("email")}
                  />
                  <label class=" font-roboto absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">
                    Your email
                  </label>
                  <div className="font-roboto text-[red] text-[0.8rem]">
                    {errors.email?.message}
                  </div>
                </div>
                <div class="relative z-0">
                  <input
                    type="tel"
                    name="phone"
                    class="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                    placeholder=" "
                    {...register("phone")}
                  />
                  <label class="font-roboto absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">
                    Contact No.
                  </label>
                  <div className="font-roboto text-[red] text-[0.8rem]">
                    {errors.phone?.message}
                  </div>
                </div>
                <div class="relative z-0 col-span-2">
                  <textarea
                    name="message"
                    rows="5"
                    class="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                    placeholder=" "
                    {...register("message")}
                  ></textarea>
                  <label class="font-roboto absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">
                    Your message
                  </label>
                  <div className="font-roboto text-[red] text-[0.8rem]">
                    {errors.message?.message}
                  </div>
                </div>
              </div>
              {/* <button
              type="submit"
              class="mt-5 rounded-md bg-[#FF6331] px-10 py-2 text-white font-roboto font-[400]"
            >
              Send Message
            </button> */}
              <Button
                type="submit"
                variant="gradient"
                size="sm"
                className="lg:inline-block font-roboto font-[400] text-[#fff] bg-[#FF6331] hover:bg-[#fff] hover:text-[#FF6331] focus:bg-[#FF6331] focus:text-white active:bg-[#FF6331] active:text-white border-[#FF6331] border-2 mt-8"
              >
                <span>Submit</span>
              </Button>
            </form>
          ) : (
            <div className="sm:text-left">
              <h1 className="font-notoSansHK font-[400] text-[#FF6331] text-2xl font-[600]">
                Thank you for contacting us!
              </h1>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
