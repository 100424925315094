import { useState, useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
export default function NavBar() {
  const [openNav, setOpenNav] = useState(false);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal font-roboto"
      >
        <Link
          to="/"
          className="flex items-center font-bold text-[#1C1C1C]"
          onClick={() => {
            const section = document.querySelector("#banner");
            section.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Home
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal font-roboto font-bold text-[#1C1C1C]"
      >
        <Link
          to="/"
          className="flex items-center"
          onClick={() => {
            const section = document.querySelector("#about-us");
            section.scrollIntoView({ behavior: "smooth" });
          }}
        >
          About Us
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal font-roboto font-bold text-[#1C1C1C]"
      >
        <Link
          to="/"
          className="flex items-center"
          onClick={() => {
            const section = document.querySelector("#projects");
            section.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Projects
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-normal font-roboto font-bold text-[#1C1C1C]"
      >
        <Link
          to="/"
          className="flex items-center"
          onClick={() => {
            const section = document.querySelector("#services");
            section.scrollIntoView({ behavior: "smooth" });
          }}
        >
          Services
        </Link>
      </Typography>
    </ul>
  );

  return (
    <Navbar className="sticky inset-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-10 lg:py-4 bg-[#fafafa]">
      <div className="flex items-center justify-between text-blue-gray-900">
        {/* image logo with text in the same line as the logo image  */}
        <div className="flex items-center gap-4 justify-between">
          <img
            src={logo}
            alt="Secure Structure Logo"
            className="mr-1 cursor-pointer py-1.5 font-medium h-16 w-auto -mt-3"
          />
          <Typography
            as="a"
            href="/"
            className="mr-4 cursor-pointer py-1.5 font-medium leading-tight font-roboto text-[#1C1C1C] font-[700]"
          >
            Secure Structure Consultancy <br />
            and Construction Private Limited
          </Typography>
        </div>
        <div className="flex items-center gap-4">
          <div className="mr-4 hidden lg:block">{navList}</div>
          <Button
            variant="gradient"
            size="sm"
            onClick={() => {
              const section = document.querySelector("#contact-us");
              section.scrollIntoView({ behavior: "smooth" });
            }}
            className="hidden lg:inline-block font-roboto font-[400] text-[#499BD5] hover:bg-[#499BD5] hover:text-white focus:bg-[#499BD5] focus:text-white active:bg-[#499BD5] active:text-white border-[#499BD5] border-2"
          >
            <span>Let's Build Together</span>
          </Button>
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="orange"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="orange"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
      </div>
      <MobileNav open={openNav}>
        {navList}
        <Button
          variant="gradient"
          size="sm"
          fullWidth
          className="mb-2 font-roboto font-[400] text-[#499BD5] hover:bg-[#499BD5] hover:text-white focus:bg-[#499BD5] focus:text-white active:bg-[#499BD5] active:text-white border-[#499BD5] border-2"
        >
          <span>Let's Build Together</span>
        </Button>
      </MobileNav>
    </Navbar>
  );
}
