import React from "react";
import { Typography } from "@material-tailwind/react";
export default function Services() {
    return (
        <section id="services" className="py-14 mx-auto bg-[#111e25]">
            <h2
                className="text-[#FF6331] font-notoSansHK font-[700] text-center"
                data-aos="fade-down"
                data-aos-duration="500"
                data-aos-delay="200"
            >
                OUR SERVICES
            </h2>
            <Typography
                variant="h1"
                className="text-[#ffffff] text-[1.6rem] sm:text-[4rem] font-notoSansHK font-[700] text-center mt-6 w-[80%] mx-auto"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
            >
                Building on trust, designed for strength.
            </Typography>
            <Typography
                variant="p"
                className="text-[#ffffff] text-[1rem] sm:text-[1.5rem] font-notoSansHK font-[400] text-center mt-6 w-[80%] sm:w-[50%] mx-auto"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
            >
                Secure Structure Consultancy and Construction Pvt. Ltd. we don't
                just design structures, we create the foundations for safer,
                more efficient, and more sustainable built environments.
            </Typography>
            <div className="flex justify-center mt-10" data-aos="fade-down">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-12 w-12 animate-bounce text-[#ffffff]"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={3}
                        d="M19 14l-7 7m0 0l-7-7m7 7V3"
                    />
                </svg>
            </div>
        </section>
    );
}
