import React, { useState } from "react";
import { Typography, Button } from "@material-tailwind/react";
import ContactForm from "../contactForm";
export default function ContactUs() {
  const [openForm, setOpenForm] = useState(false);
  const handleOpenForm = () => {
    setOpenForm(true);
  };
  return (
    <section
      className="container py-8 md:px-20 px-10 flex flex-col flex-col justify-center"
      id="contact-us"
    >
      <div className="">
        <h2
          className="text-[#FF6331] font-roboto font-[700] uppercase mb-6"
          data-aos="fade-down"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          Contact Us
        </h2>
        <Typography
          variant="h1"
          className="text-[#1C1C1C] text-[2rem] sm:text-[4rem] font-notoSansHK font-[700] text-center mt-4 sm:leading-[4rem] sm:w-[60%] text-left mb-2"
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          Let's Partner <br /> Together
        </Typography>
        {!openForm && (
          <Button
            onClick={handleOpenForm}
            variant="gradient"
            size="sm"
            className="lg:inline-block font-roboto font-[400] text-[#fff] bg-[#469cd8] hover:bg-[#fff] hover:text-[#499BD5] focus:bg-[#499BD5] focus:text-white active:bg-[#499BD5] active:text-white border-[#469cd8] border-2 mt-8"
            data-aos="zoom-in"
            data-aos-duration="500"
          >
            <span>Contact with us</span>
          </Button>
        )}
        {openForm && <ContactForm />}
      </div>
    </section>
  );
}
