import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./style.css";
import { Mousewheel, Pagination, Autoplay } from "swiper";
import { Typography } from "@material-tailwind/react";
import ServiceCard from "../serviceCard";

const services = [
    {
        title: "Structural Design & Analysis",
        description:
            "Developing precise, efficient, and safe structural systems for different government organisation, commercial, residential, industrial, and infrastructure projects",
    },
    {
        title: "Retrofit & Rehabilitation",
        description:
            "Assessing and upgrading existing structures to meet modern safety standards and enhance building performance",
    },
    {
        title: "Seismic Engineering",
        description:
            "Designing resilient structures that can withstand complex geological challenges and dynamic environmental conditions",
    },
    {
        title: "Sustainability Consulting",
        description:
            "Integrating green design principles and innovative materials to create environmentally responsible structural solutions",
    },
];
export default function ServiceList() {
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty("--progress", 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };
    return (
        <section className="container py-8 md:px-20 px-10 mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-8">
                <div
                    className="col-span-2 sm:col-span-1 flex flex-col justify-center align-center"
                    data-aos="fade-right"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500"
                    data-aos-delay="300"
                >
                    <Typography
                        variant="h1"
                        className="text-[#1C1C1C] font-roboto font-[700] text-[1.8rem] sm:text-[3rem] sm:leading-[4rem]"
                    >
                        Our Services
                    </Typography>
                    <Typography
                        variant="p"
                        className="text-[#1C1C1C] font-roboto font-[400] text-[0.9rem] sm:text-[1.1rem] mt-4 w-[100%] sm:w-[90%]"
                    >
                        We specialize in providing comprehensive structural
                        engineering services, including:
                    </Typography>
                </div>
                <div
                    className="col-span-2 sm:col-span-1 flex justify-center align-center"
                    data-aos="fade-left"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500"
                    data-aos-delay="300"
                >
                    <Swiper
                        direction={"vertical"}
                        slidesPerView={1}
                        spaceBetween={30}
                        mousewheel={true}
                        rewind={true}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Mousewheel, Pagination, Autoplay]}
                        onAutoplayTimeLeft={onAutoplayTimeLeft}
                        className="mySwiper"
                    >
                        {services.map((service, index) => (
                            <SwiperSlide key={index}>
                                <ServiceCard
                                    number={index + 1}
                                    title={service.title}
                                    description={service.description}
                                />
                            </SwiperSlide>
                        ))}
                        <div className="autoplay-progress" slot="container-end">
                            <svg viewBox="0 0 48 48" ref={progressCircle}>
                                <circle cx="24" cy="24" r="20"></circle>
                            </svg>
                            <span ref={progressContent}></span>
                        </div>
                    </Swiper>
                </div>
            </div>
        </section>
    );
}
