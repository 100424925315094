import React from "react";

export default function NotFoundPage() {
  return (
    <div class="flex items-center justify-center min-h-screen bg-gray-100">
      <div class="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
        <div class="max-w-md mx-auto space-y-6">
          <h2 class="flex flex-row flex-nowrap items-center my-8">
            <span
              class="flex-grow block border-t border-black"
              aria-hidden="true"
              role="presentation"
            ></span>
            <span class="flex-none block mx-4   px-4 py-2.5 text-xs leading-none font-medium uppercase bg-black text-white">
              Looks like you're lost
            </span>
            <span
              class="flex-grow block border-t border-black"
              aria-hidden="true"
              role="presentation"
            ></span>
          </h2>
        </div>
      </div>
    </div>
  );
}
