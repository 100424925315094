import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles.css";
import { Navigation } from "swiper";
import CardComponent from "../card";
import AdministrativeBuilding from "../../assets/images/ADMINISTRATIVE BUILDING.jpg";
import AcademicBlock from "../../assets/images/Academic Block.jpg";
import AcademicBlock1 from "../../assets/images/Academic Block-1.jpg";
import Auditorium from "../../assets/images/AUDITORIUM.jpg";
import PrincipalFront from "../../assets/images/Principal Front.jpg";
import VicePrincipal from "../../assets/images/VICE PRINCIPAL.jpg";
import MedicalOfficer from "../../assets/images/MEDICAL OFFICER.jpg";
import Workshop from "../../assets/images/Workshop.jpg";
import TopPlan from "../../assets/images/Top.jpg";
import Bstdc from "../../assets/images/BSTDC.jpeg";
import GymBihar from "../../assets/images/GYM Bihar Sarif.jpeg";
import LaxmiNarayanComplex from "../../assets/images/Laxmi Narayan Complex.jpeg";
import UmeshNarayanComplex from "../../assets/images/UMESH ELITE.jpeg";
import S2F from "../../assets/images/S2F.jpeg";
import G14 from "../../assets/images/G+14.jpg";

const projects = [
    {
        title: "Administrative Building",
        description: "ITI  Digha",
        image: AdministrativeBuilding,
    },
    {
        title: "Academic Block",
        description: "ITI  Digha",
        image: AcademicBlock,
    },
    {
        title: "Academic Block",
        description: "ITI  Digha",
        image: AcademicBlock1,
    },
    {
        title: "Auditorium",
        description: "ITI  Digha",
        image: Auditorium,
    },
    {
        title: "Principal",
        description: "ITI  Digha",
        image: PrincipalFront,
    },
    {
        title: "Vice Principal",
        description: "ITI  Digha",
        image: VicePrincipal,
    },
    {
        title: "Medical Officer",
        description: "ITI  Digha",
        image: MedicalOfficer,
    },
    {
        title: "Workshop",
        description: "ITI  Digha",
        image: Workshop,
    },
    {
        title: "Digha Top Plan",
        description: "ITI  Digha",
        image: TopPlan,
    },
    {
        title: "BSTDC",
        description: "ITI  Digha",
        image: Bstdc,
    },
    {
        title: "GYM Bihar Sarif",
        description: "ITI  Digha",
        image: GymBihar,
    },
    {
        title: "Laxmi Narayan Complex",
        description: "ITI  Digha",
        image: LaxmiNarayanComplex,
    },
    {
        title: "Umesh Elite",
        description: "ITI  Digha",
        image: UmeshNarayanComplex,
    },
    {
        title: "S2F",
        description: "ITI  Digha",
        image: S2F,
    },
    {
        title: "G+14",
        description: "ITI  Digha",
        image: G14,
    },
];
export default function Projects() {
    return (
        <section className="container px-10 py-8 mx-auto" id="projects">
            <h2
                className="text-[#FF6331] font-notoSansHK font-[700] uppercase mb-8"
                data-aos="fade-down"
                data-aos-duration="500"
                data-aos-delay="100"
            >
                Featured projects
            </h2>
            <Swiper
                rewind={true}
                slidesPerView={3}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    639: {
                        slidesPerView: 1,
                    },
                    865: {
                        slidesPerView: 2,
                    },
                    1000: {
                        slidesPerView: 3,
                    },
                }}
                spaceBetween={10}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-offset="0"
                data-aos-duration="400"
            >
                {projects.map((project, index) => (
                    <SwiperSlide key={index}>
                        <CardComponent {...project} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
}
