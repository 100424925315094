import React from "react";

export default function About() {
  return (
    <section className="container py-8 md:px-20 px-4 mx-auto" id="about-us">
      <h2
        className="text-[#FF6331] font-notoSansHK font-[700]"
        data-aos="fade-down"
        data-aos-duration="500"
        data-aos-delay="200"
      >
        WHO WE ARE
      </h2>
      {/* div with 2 columns */}
      <div
        className="grid grid-cols-2 gap-4 py-8"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-easing="ease-in-sine"
      >
        {/* left column */}
        <div className="col-span-2 lg:col-span-1">
          <h1 className="text-[#1C1C1C] font-roboto font-[300] text-[1.8rem] sm:text-[4.2rem] sm:leading-[5.2rem]  uppercase">
            <span className="font-[700]">Delivering</span> our Clients more
            projects <span className="font-[700]">clarity</span>, greater
            insight, and <span className="font-[700]">less chaos.</span>
          </h1>
        </div>
        {/* right column */}
        <div
          className="col-span-2 lg:col-span-1 flex flex-col justify-end"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-easing="ease-in-sine"
        >
          <p className="text-[#1C1C1C] font-notoSansHK font-[400] text-[1rem] leading-[1.8rem] sm:w-[85%] text-left sm:px-2">
            We are a team of highly skilled and experienced engineers,
            architects, and design professionals. Our multidisciplinary approach
            enables us to tackle complex engineering challenges from various
            perspectives, ensuring holistic and integrated solutions. We are a
            leading engineering design consultancy firm dedicated to providing
            innovative solutions and expertise across a wide range of
            engineering disciplines. With a passion for excellence and a
            commitment to delivering exceptional results, we are dedicated to
            partnering with our clients to bring their engineering visions into
            reality. we offer comprehensive engineering services tailored to
            meet the unique needs of our clients.
          </p>
        </div>
      </div>
    </section>
  );
}
