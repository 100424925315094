import { Typography } from "@material-tailwind/react";
import React from "react";
import CountUp from "react-countup";
export default function Achievement() {
  return (
    <section
      className="container px-14 py-8 mx-auto"
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
    >
      <div className=" bg-[#F4F0EF]">
        <div
          class="grid grid-cols-1 sm:grid-cols-2 gap-4 justify-center items-center"
          data-aos="fade-down"
        >
          <div className="text-center py-10">
            <div>
              <Typography
                variant="h1"
                className="text-[#1C1C1C] font-roboto font-[600] text-[4.2rem] w-[10rem] mx-auto sm:text-left"
              >
                <CountUp
                  end={100}
                  duration={4}
                  enableScrollSpy={true}
                  scrollSpyDelay={500}
                  scrollSpyOnce={false}
                />
                +
              </Typography>
              <Typography
                variant="h1"
                className="text-[#1C1C1C] font-notoSansHK font-[300] uppercase text-[1.5rem] w-[10rem] mx-auto sm:text-left"
              >
                Satisfaction Clients
              </Typography>
            </div>
          </div>
          <div className="text-center py-10">
            <div>
              <Typography
                variant="h1"
                className="text-[#1C1C1C] font-roboto font-[600] text-[4.2rem] w-[10rem] mx-auto sm:text-left"
              >
                <CountUp
                  end={60}
                  duration={2.4}
                  enableScrollSpy={true}
                  scrollSpyDelay={500}
                  scrollSpyOnce={false}
                />
                +
              </Typography>
              <Typography
                variant="h1"
                className="text-[#1C1C1C] font-notoSansHK font-[300] uppercase text-[1.5rem] w-[10rem] mx-auto sm:text-left"
              >
                Projects completed
              </Typography>
            </div>
          </div>
          {/* <div className="text-center py-10">
            <div>
              <Typography
                variant="h1"
                className="text-[#1C1C1C] font-roboto font-[600] text-[4.2rem] w-[10rem] mx-auto sm:text-left"
              >
                <CountUp
                  end={50}
                  duration={2}
                  enableScrollSpy={true}
                  scrollSpyDelay={500}
                  scrollSpyOnce={false}
                />
                +
              </Typography>
              <Typography
                variant="h1"
                className="text-[#1C1C1C] font-notoSansHK font-[300] uppercase text-[1.5rem] w-[10rem] mx-auto sm:text-left"
              >
                architect worldwide
              </Typography>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
