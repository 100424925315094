import React, { useEffect } from "react";
import { Navigate, Routes, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./pages/homePage";
import AdminPage from "./pages/adminPage";
import LoginPage from "./pages/loginPage";
import NotFoundPage from "./pages/notFoundPage";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
const App = () => {
  const isAuth = Boolean(useSelector((state) => state.token));
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/admin"
          element={isAuth ? <AdminPage /> : <Navigate to="/login" />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
