import React from "react";
import logo from "../../assets/images/logo.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "state";
export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const userName = e.target.username.value;
      const password = e.target.password.value;
      const loggedInResponse = await fetch(
        "https://sscbackend-app-styho.ondigitalocean.app/api/auth/login",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userName, password }),
        }
      );
      const loggedIn = await loggedInResponse.json();
      if (loggedIn.message === "Invalid credentials") {
        throw new Error("Invalid credentials");
      }
      if (loggedIn) {
        dispatch(
          setLogin({
            user: loggedIn.user,
            token: loggedIn.token,
          })
        );
        navigate("/admin");
        console.log("Success");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <section data-aos="fade-down" className="h-[100vh] ">
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 m-auto bg-[#fafafa]">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-20 w-auto" src={logo} alt="Your Company" />
          <h2 className="font-roboto mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="space-y-8"
            action="#"
            method="POST"
            autoComplete="off"
            onSubmit={handleFormSubmit}
          >
            {/* username */}
            <div class="relative z-0">
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="off"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                class="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                placeholder=" "
              />
              <label class="font-roboto absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">
                Username
              </label>
            </div>
            {/* Password */}
            <div class="relative z-0">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="off"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                class="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                placeholder=" "
              />
              <label class="font-roboto absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">
                Password
              </label>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm leading-6 font-roboto font-[400] text-[#fff] bg-[#FF6331] hover:bg-[#fff] hover:text-[#FF6331] focus:bg-[#FF6331] focus:text-white active:bg-[#FF6331] active:text-white border-[#FF6331] border-2"
              >
                Login
              </button>
            </div>
            {error && <div className="text-red-500 text-center">{error}</div>}
          </form>
        </div>
      </div>
    </section>
  );
}
