import React from "react";
import responseProfileImage from "../../assets/images/ResponseImg.jpeg";
import adminBannerImg from "../../assets/images/adminBannerImg.jpg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "state";
export default function AdminPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const handleLogout = () => {
    dispatch(setLogout());
    navigate("/login");
  };
  //loading state
  const [isLoading, setIsLoading] = useState(true);
  //Random Quote API
  const [quote, setQuote] = useState("");
  const getQuote = async () => {
    try {
      const response = await fetch("https://api.quotable.io/random");
      const data = await response.json();
      setQuote(data);
    } catch (error) {
      console.log(error);
    }
  };
  // Fetching all the form data from the database
  const [formDatas, setFormDatas] = useState([]);
  const getFormDatas = async () => {
    try {
      const response = await fetch("https://sscbackend-app-styho.ondigitalocean.app/api/form/getForm", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setFormDatas(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getFormDatas();
    getQuote();
  }, []);
  return (
    <section>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 m-auto bg-[#fafafa]">
        <div
          className="relative overflow-hidden bg-cover bg-no-repeat p-12 text-center"
          style={{
            backgroundImage: `url(${adminBannerImg})`,
            backgroundSize: "cover",
            backgroundPosition: "0% 15%",
            height: "400px",
          }}
        >
          <div
            className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
          >
            <div className="flex h-full items-center justify-center">
              <div className="text-white">
                <h2 className="mb-4 text-4xl font-semibold font-notoSansHK">
                  Hello Admin!
                </h2>
                <h4 className="mb-6 text-xl font-semibold font-roboto">
                  {quote.content}
                </h4>
                <button
                  type="button"
                  onClick={handleLogout}
                  className="rounded border-2 border-neutral-50 font-roboto px-7 pb-[8px] pt-[10px] text-sm font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  Log Off
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex justify-end">
          <button
            onClick={handleLogout}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Logout
          </button>
        </div> */}
        {/* Listing of all the forms */}
        <div>
          <div class="flex flex-col p-2 py-6 m-h-screen">
            <div className="flex flex-col gap-4 lg:p-4 p-2  rounde-lg m-2">
              <div className="lg:text-2xl md:text-xl text-lg lg:p-3 p-1 font-black text-gray-700 font-notoSansHK">
                Form Responses:
              </div>
              {isLoading ? (
                <div
                  className="w-16 h-16 border-4 border-dashed rounded-full animate-spin dark:border-orange-400 mx-auto my-4"
                  bis_skin_checked="1"
                ></div>
              ) : (
                <>
                  {formDatas.map(
                    ({
                      firstName,
                      lastName,
                      email,
                      phone,
                      message,
                      createdAt,
                    }) => {
                      const formattedDate = new Date(
                        createdAt
                      ).toLocaleDateString("en-GB");
                      //time in hh:mm format
                      const time = new Date(createdAt).toLocaleTimeString(
                        "en-US",
                        {
                          hour: "numeric",
                          minute: "numeric",
                        }
                      );
                      return (
                        <div className="flex font-notoSansHK items-center justify-between w-full p-2 lg:rounded-full md:rounded-full hover:bg-gray-100 cursor-pointer border-2 rounded-lg">
                          <div className="lg:flex md:flex items-center">
                            <div className="h-12 w-12 mb-2 lg:mb-0 border md:mb-0 rounded-full mr-3">
                              <img
                                className="h-12 w-12 rounded-full object-cover"
                                src={responseProfileImage}
                                alt="profile"
                              />
                            </div>

                            <div className="flex flex-col p-1">
                              <div className="text-md leading-3 text-gray-700 font-bold w-full font-roboto">
                                {firstName} {lastName}{" "}
                                <span className="text-xs text-gray-500">
                                  • {time}
                                </span>
                                <span className="text-xs text-gray-500">
                                  • {formattedDate}
                                </span>
                              </div>
                              <div className=" text-gray-500 font-bold w-full font-roboto">
                                <span className="text-sm text-gray-500">
                                  {email}{" "}
                                </span>{" "}
                                <span className="text-sm text-gray-500">
                                  • {phone}{" "}
                                </span>
                              </div>

                              <div className="text-xs text-gray-600 w-[80%] mt-1 font-roboto">
                                {message}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <!--Footer container--> */}
      <footer className="bg-[#111e25] text-center text-white bg-[#263a48]">
        <div className="container pt-9 m-auto">
          <div className="mb-9 flex justify-center">
            <a
              href="https://saurav4geeks.github.io/portfolio/"
              class="mr-9 text-neutral-800 dark:text-neutral-200"
            >
              <svg
                className="w-5 h-5"
                viewBox="0 0 1792 1792"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M553 1399l-50 50q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23t-10 23l-393 393 393 393q10 10 10 23t-10 23zm591-1067l-373 1291q-4 13-15.5 19.5t-23.5 2.5l-62-17q-13-4-19.5-15.5t-2.5-24.5l373-1291q4-13 15.5-19.5t23.5-2.5l62 17q13 4 19.5 15.5t2.5 24.5zm657 651l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23t-10 23z" />
              </svg>
            </a>
            <a
              href="mailto:sauravsuman5980@gmail.com"
              className="mr-9 text-neutral-800 dark:text-neutral-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/in/saurav-suman-ind/"
              className="mr-9 text-neutral-800 dark:text-neutral-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
              </svg>
            </a>
            <a
              href="https://github.com/saurav4geeks"
              className="text-neutral-800 dark:text-neutral-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
              </svg>
            </a>
          </div>
        </div>

        {/* <!--Copyright section--> */}
        <div className="p-4 text-center bg-[#111e25]">
          Made with <span className="text-red-500">❤</span> by{" "}
          <a
            className="text-[#FF6331] font-notoSansHK"
            href="https://saurav4geeks.github.io/portfolio/"
          >
            Saurav Suman
          </a>
        </div>
      </footer>
    </section>
  );
}
