import React from "react";
import NavBar from "../../components/navbar";
import Banner from "../../components/banner";
import About from "../../components/about";
import Achievement from "../../components/achievement";
import Projects from "../../components/projects";
import Services from "../../components/services";
import ServiceList from "../../components/serviceList/";
import ContactUs from "../../components/contactUs";
import Footer from "../../components/footer";
export default function HomePage() {
  return (
    <div className="bg-[#fafafa]">
      <NavBar />
      <Banner />
      <About />
      <Achievement />
      <Projects />
      <Services />
      <ServiceList />
      <ContactUs />
      <Footer />
    </div>
  );
}
