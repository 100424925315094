import React from "react";
import { Typography } from "@material-tailwind/react";
export default function ServiceCard(props) {
  return (
    <div className="bg-[#f4f0ef] w-[100%] h-[100%] flex flex-col justify-center pb-4">
      <div className="px-10 flex flex-col">
        <Typography
          variant="h1"
          className="bg-gradient-to-r from-slate-900 to-slate-700 bg-clip-text text-transparent text-[1.6rem] sm:text-[4rem] font-roboto font-[700] text-left"
        >
          {props.number}
        </Typography>
        <Typography
          variant="h1"
          className="text-[#111e25] text-[1.1rem] sm:text-[1.5rem] font-notoSansHK font-[700] text-left mt-2"
        >
          {props.title}
        </Typography>
        <Typography
          variant="p"
          className="text-[#111e25] text-[0.9rem] sm:text-[1rem] font-roboto font-[400] text-left mt-4"
        >
          {props.description}
        </Typography>
      </div>
    </div>
  );
}
