import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
export default function CardComponent(props) {
  return (
    <Card className="max-w-[24rem] overflow-hidden p-4 sm:p-0">
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 rounded-none h-[13rem] w-auto"
      >
        <img src={props.image} alt="Project Img" />
      </CardHeader>
      <CardBody className="pt-4">
        <Typography
          variant="p"
          className="font-roboto text-left text-[#596481] font-[300] text-[1rem]"
        >
          {props.description}
        </Typography>
        <Typography
          variant="h2"
          className="mt-1 font-[700] font-roboto text-left text-[1.2rem]"
        >
          {props.title}
        </Typography>
      </CardBody>
    </Card>
  );
}
